import React from 'react';

// Layout
import Layout from '../containers/layout/Layout';

// Components
import Milestones from '../components/common/milestones/Milestones';
import Partners from '../components/common/partners/Partners';
import DeliverServices from '../components/common/services/DeliverServices';
import Portfolio from '../components/common/portfolio/Portfolio';
import Solutions from '../components/common/solutions/Solutions';

// Data
import {useServices} from '../graphql/services';
import {useSolutions} from '../graphql/solutions';

const Home = () => {
  const servicesData = useServices();
  const solutionsData = useSolutions();

  return (
    <Layout>
      <DeliverServices
        data={servicesData}
        servicesTitle="Ensemble is a software consulting company delivering services for:"
        className="services--homeServices"
      />
      <Solutions data={solutionsData} />
      <Milestones title="Ensemble in a nutshell:" />
      <Portfolio
        title="We build great experiences:"
        portfolioClass="portfolio-homeProjects"
        type="all"
      />
      <Partners partnersTitle="Partnering with some of the best:" />
    </Layout>
  );
};

export default Home;
