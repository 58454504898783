import React, {useState} from 'react';

// Bootstrap
import {Row, Col} from 'react-bootstrap';

// Less
import './Portfolio.less';

// Data
import {usePortfolio} from '../../../graphql/portfolio';

// Helper
import {createAssetUrl} from '../../../utils/helper';

const Portfolio = ({title, portfolioClass, type}) => {
  const portfoliosData = usePortfolio();
  const [dataLimit, setLimit] = useState(6);

  let portfolioData = '';
  let rowStyle = '';

  // set classes
  const portfolioClasses = `portfolio ${portfolioClass}`;

  // sorted data from json depending on the type parameter
  if (type === 'all') {
    portfolioData = portfoliosData.map((data) => data);
  } else {
    portfolioData = portfoliosData.filter(({node}) => {
      return node.type.includes(type);
    });
  }

  // added justify-content style
  if (
    portfolioClass === 'portfolio-relatedProjects' &&
    portfolioData.length < 3
  ) {
    rowStyle = {
      justifyContent: 'center',
    };
  } else {
    rowStyle = {
      justifyContent: 'unset',
    };
  }

  const onLoadMore = () => {
    setLimit(Object.keys(portfolioData).length);
  };

  const onLoadLess = () => {
    setLimit(6);
  };

  return (
    <div className={portfolioClasses}>
      <div className="portfolio__container">
        <h2>{title}</h2>
        <Row className="portfolio__row" style={rowStyle}>
          {portfolioData.slice(0, dataLimit).map(({node}, index) => {
            const portfolioStyles = {
              backgroundImage: `url(${createAssetUrl(node.bgImg)})`,
            };

            return (
              <Col
                className="portfolio__item"
                xs={12}
                sm={6}
                lg={4}
                key={index}
                style={portfolioStyles}
              >
                <a
                  className="portfolio__logoContainer"
                  href={createAssetUrl(node.link)}
                  target="__blank"
                >
                  <img
                    src={createAssetUrl(node.img)}
                    alt={node.imgAlt}
                    height={node.imgHeight}
                    width={node.imgWidth}
                  />
                </a>
              </Col>
            );
          })}
        </Row>
        <div className="portfolio__button">
          {Object.keys(portfolioData).length > 6 &&
            dataLimit < Object.keys(portfolioData).length && (
              <button type="button" onClick={onLoadMore}>
                Load More
              </button>
            )}

          {Object.keys(portfolioData).length === dataLimit && dataLimit > 6 && (
            <button type="button" onClick={onLoadLess}>
              Load Less
            </button>
          )}
        </div>
        <span className="portfolio__lineSeparator" />
      </div>
    </div>
  );
};

export default Portfolio;
