import React from 'react';
import {Link} from 'gatsby';

// Bootstrap
import {Container, Row, Col} from 'react-bootstrap';

// Less
import './DeliverServices.less';

// Helper
import {createAssetUrl} from '../../../utils/helper';

const DeliverServices = ({
  className,
  styles,
  servicesTitle,
  servicesSubtitle,
  data,
}) => {
  const servicesClass = `services ${className}`;

  const services = data.map(({node}, index) => (
    <Col className="services__column" xs={12} sm={6} lg={4} key={index}>
      <Link className="services__card" to={node.link}>
        <img src={createAssetUrl(node.icon)} alt={node.alt} />
        <h1>{node.title}</h1>
        {node.description && <p>{node.description}</p>}
      </Link>
    </Col>
  ));

  return (
    <div className={servicesClass} style={styles}>
      <Container>
        {servicesTitle && <h2>{servicesTitle}</h2>}
        {servicesSubtitle && <span>{servicesSubtitle}</span>}
        <Row className="services__row">{services}</Row>
      </Container>
    </div>
  );
};

export default DeliverServices;
