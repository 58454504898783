import {graphql, useStaticQuery} from 'gatsby';

export const useSolutions = () => {
  const {allSolutionsJson} = useStaticQuery(graphql`
    query Solutions {
      allSolutionsJson {
        edges {
          node {
            title
            description
            icon
          }
        }
      }
    }
  `);

  return allSolutionsJson.edges;
};
