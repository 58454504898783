import React from 'react';

// Bootstrap
import {Col, Container, Row} from 'react-bootstrap';

// Less
import './Solutions.less';

// Helper
import {createAssetUrl} from '../../../utils/helper';

const Solutions = ({data}) => {
  const solutions = data.map(({node}, index) => (
    <Col xs={12} md={6} className="mb-3" key={index}>
      <div className="d-flex">
        <img
          src={createAssetUrl(node.icon)}
          alt={node.title}
          height={75}
          width={75}
        />
        <div className="solutions__description">
          <h4 className="solutions__title">{node.title}</h4>
          <p>{node.description}</p>
        </div>
      </div>
    </Col>
  ));

  return (
    <div className="solutions">
      <h2 className="mb-5">
        Offering solutions for your digital requirements:
      </h2>
      <Container>
        <Row>{solutions}</Row>
      </Container>
    </div>
  );
};

export default Solutions;
