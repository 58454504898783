import {graphql, useStaticQuery} from 'gatsby';

export const usePortfolio = () => {
  const {allPortfolioJson} = useStaticQuery(graphql`
    query Portofolio {
      allPortfolioJson {
        edges {
          node {
            link
            title
            imgAlt
            imgHeight
            imgWidth
            type
            bgImg
            img
          }
        }
      }
    }
  `);

  return allPortfolioJson.edges;
};
