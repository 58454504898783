import {graphql, useStaticQuery} from 'gatsby';

export const useServices = () => {
  const {allServicesJson} = useStaticQuery(graphql`
    query Services {
      allServicesJson {
        edges {
          node {
            link
            title
            alt
            icon
          }
        }
      }
    }
  `);

  return allServicesJson.edges;
};
